@import "../../../variables";

.positive {
  color: $success;
}

.unchanged {
  color: $topbar-element-color;
}

.negative {
  color: $danger;
}

.hyperlink {
  text-decoration: none;
  &:hover {
    color: $primary;
    opacity: 0.7;
  }
}

.text-align {
  margin-right: 5px;
}

.stock-indicator {
  @media (hover: hover) and (pointer: fine) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media (hover: none) and (pointer: coarse) {
    position: relative;
  }
}

.stock-prefix {
  color: $breadcrumb-active-color;
  @media (hover: none) and (pointer: coarse) {
    display: none;
  }
}
