@import "../../../variables";

.nested-icon-button {
  color: $hem-link-color;
}

.card .nested-icon-button:hover {
  color: $primary !important;
}

.card:hover .appear-on-hover {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.absolute-position {
  position: absolute;
  z-index: 10;
}
.upper-left {
  top: 10px;
  left: 10px;
}
.upper-right {
  top: 10px;
  right: 10px;
}
.lower-left {
  bottom: 10px;
  left: 10px;
}
.lower-right {
  bottom: 10px;
  right: 10px;
}
.appear-on-hover {
  @media (hover: none) and (pointer: coarse) {
    visibility: visible;
    opacity: 1;
  }
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s ease-out 0.15s, opacity 0.15s ease-out;
}
.shadow {
  filter: drop-shadow(0 0 5px #000) !important;
}
