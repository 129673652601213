.user-symbols {
  display: flex;
  align-items: center;
}

.user-symbol-button {
  font-size: 0.8rem;
  background-color: transparent;
  cursor: pointer;
}

.dropdown-menu-custom {
  right: 5px;
}
