@import "../../../../variables";

input {
  &.label-in-border {
    color: inherit;
  }
  &.label-in-input {
    color: transparent;
  }
}

label.input-label {
  z-index: 1;
  pointer-events: none;

  &.label-in-border {
    top: 0;
    left: 0.5rem;
    font-size: 0.55rem;
    padding: 0 calc($size-bezel * 0.5);
    background-color: var(--bs-body-bg);
  }

  &.label-in-input {
    top: 0.625rem;
    left: 0.375rem;
    font-size: 0.875rem;
  }
}
