@import "../../../variables";

@mixin label-in-border() {
  display: block;
  position: absolute;
  left: $size-bezel * 0.6;
  top: $size-bezel * -0.6;
  padding: 0 calc($size-bezel * 0.5);
  background-color: var(--bs-body-bg);
  color: inherit;
  white-space: nowrap;
  font-size: 0.55rem;
  line-height: 1.2;
}

.input {
  position: relative;
  margin-top: 0.25rem;
  width: 100%;
  //color: $text-color;
  //background: $background-color;

  &__label {
    display: none;
    transform-origin: 0 0;
    &-visible {
      color: $text-muted;
      font-size: 0.875rem;
      display: block;
    }

    &-on-border {
      @include label-in-border;
    }
  }

  &__field {
    padding: calc($size-bezel * 0.5) $size-bezel;
    color: inherit;
    background: inherit;

    &:focus {
      color: inherit;
      background: inherit;
    }

    &:not(:placeholder-shown) {
      & + .input__label {
        @include label-in-border;
      }
    }
    &::placeholder {
      color: $text-muted !important;
    }
  }
}
