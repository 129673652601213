.custom-modal .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (hover: none) and (pointer: coarse) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.custom-modal .modal-content {
  @media (hover: none) and (pointer: coarse) {
    max-height: 100vh;
  }
  width: auto;
  max-width: 100vw;
}

.custom-modal .modal-body {
  @media (hover: none) and (pointer: coarse) {
    padding: 0;
  }
  padding: 7px;
  display: flex;
}

.media-content {
  @media (hover: none) and (pointer: coarse) {
    border-radius: 0;
    max-width: 100vw;
    max-height: 100vh;
  }
  display: block;
  border-radius: 3px;
  max-width: 90vw;
  max-height: 85vh;
  height: auto;
  width: auto;
}

@media (hover: none) and (pointer: coarse) {
  .mobile-nav-field {
    position: absolute;
    top: 0;
    width: 50%;
    transition: background-color 0.3s ease;
    z-index: 10;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }

  .image-nav {
    height: 100%;
    &:active {
      background-color: rgba(255, 255, 225, 0.5);
    }
  }

  .video-nav {
    height: 100%;
    width: calc(50% - 25px);
    &::before {
      position: absolute;
      content: "";
      height: 100%;
      width: calc(100% + 25px);
      top: 0;
      transition: background-color 0.3s ease;
      pointer-events: none;
      z-index: 10;
    }
    &.right::before {
      transform: translateX(-25px);
    }
    &:active::before {
      background-color: rgba(255, 255, 225, 0.5);
    }
  }
}

.nav-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  z-index: 10;
  filter: drop-shadow(0 0 5px #000);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s ease-out 0.15s, opacity 0.15s ease-out;
  &.left {
    left: 20px;
  }
  &.right {
    right: 20px;
  }
}

.modal-dialog:hover .nav-icon {
  @media (hover: none) and (pointer: coarse) {
    visibility: hidden;
  }
  visibility: visible;
  opacity: 0.4;
  transition-delay: 0s;
}
