.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.album-name {
  margin-top: 0;
  font-size: 2rem;
  margin-bottom: 0;
}

.album-description {
  margin-top: 0;
  font-size: 0.8rem;
  margin-bottom: 0;
}

.album-date {
  font-size: 0.8rem;
  margin-top: 0;
  margin-bottom: 3px;
}

.center-text {
  display: flex;
  justify-content: center;
  text-align: center;
}
