@import "./variables";

body {
  padding: 0 !important;
  font-family: "Nunito", sans-serif;
}

.click-target {
  cursor: pointer;

  &:hover {
    color: $primary;
    filter: brightness(110%);
  }

  &:active {
    color: lighten($primary, 20%);
  }

  &:focus {
    color: lighten($primary, 20%);
  }
}

.blur-background {
  backdrop-filter: blur(2px);
}

.text-muted {
  color: $text-muted !important;
}

.breadcrumb {
  --bs-breadcrumb-item-active-color: #{$hem-link-color};
  --bs-breadcrumb-divider-color: #{$hem-link-color};
}

#hem-top-bar a {
  color: #a5c9fe;

  &:hover {
    filter: brightness(1.2);
  }
}

.stock-prefix {
  color: #{$hem-link-color};
}

.btn-link {
  &:hover {
    filter: unquote(var(--x-link-hover-filter));
  }
}
