.image-of-the-day-container {
  position: relative;
  display: inline-block;
  align-self: center;
  border-radius: 5px;
  margin: 5px 15px 15px 15px;
}

.image-of-the-day-container > img {
  display: block;
}

.image-of-the-day-container::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.2), inset -1px -1px 1px rgba(0, 0, 0, 0.2);
}

.image-of-the-day {
  max-width: 100%;
  max-height: 500px;
  width: auto;
  height: auto;
  border-radius: 5px;
  object-fit: contain;
}
