@import "../../../variables";

.square-card-img-wrapper {
  position: relative;
  width: 100%; /* This can be set to the width you prefer */
  padding-bottom: 100%; /* This maintains a 1:1 aspect ratio */
  overflow: hidden; /* This ensures the image is cropped */
}

.square-card-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will cover the area and crop the excess */
}

.square-card-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover; /* This will cover the area and crop the excess */
}
