$color-mode-type: data;

$breadcrumb-margin-bottom: 0;
$enable-rounded: true;
$enable-shadows: true;
$size-bezel: 0.5rem;
$border-widths: (
  1: 0.5px,
  2: 1px,
  3: 3px,
  4: 4px,
  5: 5px,
);
$hem-link-color: #96adc0;
$hem-top-bar-bg-color: #253674;
$topbar-element-color: $hem-link-color;
$text-muted: var(--bs-secondary-color);

@import "../node_modules/bootstrap/scss/bootstrap";

@include color-mode(light) {
  :root {
    --x-link-hover-filter: "brightness(0.6)";
  }
  .bg-dark {
    background-color: #{$hem-top-bar-bg-color} !important;
  }
  .dropdown-menu {
    --bs-dropdown-link-hover-bg: #{$hem-top-bar-bg-color};
  }
}

@include color-mode(dark, true) {
  --bs-body-bg: #303b45;
  --x-link-hover-filter: "brightness(1.2)";
}
